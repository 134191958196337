import { ErrorBoundary } from '@sentry/react';
import React, { Suspense } from 'react';
import { QueryErrorResetBoundary } from 'react-query';

import { LoadingDrawer } from '../components/Drawers/LoadingDrawer';
import {
  ErrorFallbackDrawer,
  ErrorFallbackProps,
  GenericErrorFallback,
} from '../components/error_fallbacks';
import { Nav } from '../components/Nav';
import { darkThemeSelector, shadows, styled } from '../stitches';
import { LoadingFallback } from './LoadingFallback';

const Layout = styled('div', {
  height: '100%',
  width: '100%',
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  '@maxSm': {
    flexDirection: 'column',
  },
});

const MainContainer = styled('div', {
  flex: 1,
  overflow: 'auto',
  webkitOverflowScrolling: 'touch',
  variants: {
    drawerVisible: {
      true: {
        display: 'none',
        '@sm': {
          display: 'block',
        },
      },
      false: {},
    },
  },
});

const SidebarContainer = styled('div', {
  gridArea: 'sidebar',
  display: 'none',
  overflow: 'auto',
  webkitOverflowScrolling: 'touch',
  '@sm': {
    minWidth: 260,
    maxWidth: 300,
    flex: 1,
    display: 'block',
  },
});

const MobileMenuToggleContainer = styled('div', {
  gridArea: 'menu-toggle',
  boxShadow: shadows.fenceBottomLight,
  [darkThemeSelector]: {
    boxShadow: shadows.fenceBottomDark,
  },
  '@sm': {
    display: 'none',
  },
});

const ErrorFallbackDrawerInContainer = (props: ErrorFallbackProps) => (
  <ErrorFallbackDrawer {...props} />
);

export interface SidebarMainDrawerLayoutProps {
  mobileSidebarToggle: React.ReactNode;
  mobileSidebar?: React.ReactNode;
  desktopSidebar: React.ReactNode;
  main: React.ReactNode;
}

export const SidebarAndMainLayout = ({
  desktopSidebar,
  main,
  mobileSidebarToggle,
  mobileSidebar,
}: SidebarMainDrawerLayoutProps) => (
  <Layout>
    <MobileMenuToggleContainer>{mobileSidebarToggle}</MobileMenuToggleContainer>
    {mobileSidebar}
    <SidebarContainer>{desktopSidebar}</SidebarContainer>
    {main}
  </Layout>
);

const MainAndDrawerContainer = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',
  overflow: 'auto',
});

export const MainAndDrawerLayout = ({
  main,
  drawer,
}: {
  main: React.ReactNode;
  drawer?: React.ReactNode;
}) => {
  const location = Nav.useRegionLocation('root');
  const drawerLocation = Nav.useRegionLocation('drawer');
  return (
    <MainAndDrawerContainer>
      <MainContainer>
        <QueryErrorResetBoundary
          // TRICKY: Pass key to the error boundary to force re-render when route changes
          key={location.pathname}
        >
          {({ reset }) => (
            <ErrorBoundary onReset={reset} fallback={GenericErrorFallback}>
              <Suspense fallback={<LoadingFallback />}>{main}</Suspense>
            </ErrorBoundary>
          )}
        </QueryErrorResetBoundary>
      </MainContainer>
      <ErrorBoundary key={drawerLocation?.pathname ?? ''} fallback={ErrorFallbackDrawerInContainer}>
        <Suspense fallback={<LoadingDrawer />}>{drawer}</Suspense>
      </ErrorBoundary>
    </MainAndDrawerContainer>
  );
};
