import { bits, bytes, GenericMeasure, Measure, seconds } from 'safe-units';
import { match } from 'ts-pattern';

export const kilobits = Measure.of(1000, bits, 'kb');
export const megabits = Measure.of(1000, kilobits, 'Mb');
export const gigabits = Measure.of(1000, megabits, 'Gb');
export const terabits = Measure.of(1000, gigabits, 'Tb');

// TODO: APPENG-520
/* eslint-disable @typescript-eslint/naming-convention */
/**
 * NOC originally used definitions of data rates using a factor of 1024
 * rather than 1000. These values are included here for compatibility until
 * APPENG-520 is worked.
 */
export const LEGACY_kilobits = Measure.of(1024, bits, 'kb');
export const LEGACY_megabits = Measure.of(1024, LEGACY_kilobits, 'Mb');
export const LEGACY_gigabits = Measure.of(1024, LEGACY_megabits, 'Gb');
export const LEGACY_terabits = Measure.of(1024, LEGACY_gigabits, 'Tb');
/* eslint-enable @typescript-eslint/naming-convention */

export const kilobytes = Measure.of(1024, bytes, 'kB');
export const megabytes = Measure.of(1024, kilobytes, 'MB');
export const gigabytes = Measure.of(1024, megabytes, 'GB');
export const terabytes = Measure.of(1024, gigabytes, 'TB');

export const bitsPerSecond = bits.per(seconds).withSymbol('bit/s');
export const kilobitsPerSecond = kilobits.per(seconds).withSymbol('kb/s');
export const megabitsPerSecond = megabits.per(seconds).withSymbol('Mb/s');
export const gigabitsPerSecond = gigabits.per(seconds).withSymbol('Gb/s');
export const terabitsPerSecond = terabits.per(seconds).withSymbol('Tb/s');

// TODO: APPENG-520
/* eslint-disable @typescript-eslint/naming-convention */
export const LEGACY_bitsPerSecond = bits.per(seconds).withSymbol('bit/s');
export const LEGACY_kilobitsPerSecond = LEGACY_kilobits.per(seconds).withSymbol('kb/s');
export const LEGACY_megabitsPerSecond = LEGACY_megabits.per(seconds).withSymbol('Mb/s');
export const LEGACY_gigabitsPerSecond = LEGACY_gigabits.per(seconds).withSymbol('Gb/s');
export const LEGACY_terabitsPerSecond = LEGACY_terabits.per(seconds).withSymbol('Tb/s');
/* eslint-enable @typescript-eslint/naming-convention */

export const bytesPerSecond = kilobytes.per(seconds).withSymbol('byte/s');
export const kilobytesPerSecond = kilobytes.per(seconds).withSymbol('kB/s');
export const megabytesPerSecond = megabytes.per(seconds).withSymbol('MB/s');
export const gigabytesPerSecond = gigabytes.per(seconds).withSymbol('GB/s');
export const terabytesPerSecond = terabytes.per(seconds).withSymbol('TB/s');

export interface DataRate extends GenericMeasure<number, { memory: '1'; time: '-1' }> {}

export const formatFixed = <T extends GenericMeasure<number, any>>(
  value: T,
  unit: T,
  precision: number = 0,
): string => `${value.over(unit).value.toFixed(precision)} ${unit.symbol}`;

/**
 * Formats a data rate using a suitable unit based in **bits**. For example,
 * if the value is less than 2kb/s, it will be formatted using bits/s; if less
 * than 2Mb/s, then kb/s; and so on.
 *
 * @param rate The data rate
 * @param quantity The quantity that the data rate is measured in
 * @param precision The number of decimal places to display
 */
export const formatDataRateBits = (rate: number, quantity: DataRate, precision: number = 0) =>
  match(Measure.of(rate, quantity))
    .when(
      (value) => value.lt(kilobitsPerSecond.scale(2)),
      (value) => formatFixed(value, bitsPerSecond, 0),
    )
    .when(
      (value) => value.lt(megabitsPerSecond.scale(2)),
      (value) => formatFixed(value, kilobitsPerSecond, precision),
    )
    .when(
      (value) => value.lt(gigabitsPerSecond.scale(2)),
      (value) => formatFixed(value, megabitsPerSecond, precision),
    )
    .when(
      (value) => value.lt(terabitsPerSecond.scale(2)),
      (value) => formatFixed(value, gigabitsPerSecond, precision),
    )
    .otherwise((value) => formatFixed(value, quantity, precision));

// TODO: APPENG-520
// eslint-disable-next-line @typescript-eslint/naming-convention
export const LEGACY_formatDataRateBits = (
  rate: number,
  quantity: DataRate,
  precision: number = 0,
) =>
  match(Measure.of(rate, quantity))
    .when(
      (value) => value.lt(LEGACY_kilobitsPerSecond.scale(2)),
      (value) => formatFixed(value, LEGACY_bitsPerSecond, 0),
    )
    .when(
      (value) => value.lt(LEGACY_megabitsPerSecond.scale(2)),
      (value) => formatFixed(value, LEGACY_kilobitsPerSecond, precision),
    )
    .when(
      (value) => value.lt(LEGACY_gigabitsPerSecond.scale(2)),
      (value) => formatFixed(value, LEGACY_megabitsPerSecond, precision),
    )
    .when(
      (value) => value.lt(LEGACY_terabitsPerSecond.scale(2)),
      (value) => formatFixed(value, LEGACY_gigabitsPerSecond, precision),
    )
    .otherwise((value) => formatFixed(value, quantity, precision));

/**
 * Formats a data rate using a suitable unit based in **bytes**. For example,
 * if the value is less than 2kB/s, it will be formatted using bytes/s; if less
 * than 2MB/s, then kB/s; and so on.
 *
 * @param rate The data rate
 * @param quantity The quantity that the data rate is measured in
 * @param precision The number of decimal places to display
 */
export const formatDataRateBytes = (rate: number, quantity: DataRate, precision: number = 0) =>
  match(Measure.of(rate, quantity))
    .when(
      (value) => value.lt(kilobytesPerSecond.scale(2)),
      (value) => formatFixed(value, bytesPerSecond, 0),
    )
    .when(
      (value) => value.lt(megabytesPerSecond.scale(2)),
      (value) => formatFixed(value, kilobytesPerSecond, precision),
    )
    .when(
      (value) => value.lt(gigabytesPerSecond.scale(2)),
      (value) => formatFixed(value, megabytesPerSecond, precision),
    )
    .when(
      (value) => value.lt(terabytesPerSecond.scale(2)),
      (value) => formatFixed(value, gigabytesPerSecond, precision),
    )
    .otherwise((value) => formatFixed(value, quantity, precision));
