import { Body2, Icon, ListItem, space } from '@meterup/metric';
import React from 'react';
import { Link as ReactRouterLink, LinkProps } from 'react-router-dom';

import { colors, fontWeights, styled } from '../stitches';

const ViewAllLink = styled(Body2, {
  display: 'inline-flex',
  alignItems: 'center',
  gap: 8,
  color: colors['blue-600'],
  fontWeight: fontWeights.medium,
});

const BaseViewAllListItem = styled(ReactRouterLink, ListItem, {
  justifyContent: 'center',
});

export const ListItemViewAllLink = ({ children, ...props }: LinkProps) => (
  <BaseViewAllListItem as={ReactRouterLink} {...props}>
    <ViewAllLink>
      {children}
      <Icon icon="chevronRight" size={space(12)} />
    </ViewAllLink>
  </BaseViewAllListItem>
);
