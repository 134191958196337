import {
  BasicSelect,
  BasicSelectItem,
  CompositeField,
  FieldContainer,
  ListItemHeader,
  ListTitle,
  PrimaryField,
  PrimaryFieldComposite,
  SecondaryField,
  SecondaryFieldComposite,
  Textarea,
  TextInput,
} from '@meterup/metric';
import { api } from '@meterup/proto';
import { useFormikContext } from 'formik';
import React from 'react';
import { useQuery } from 'react-query';

import { getAllProviders, getNetworkInterfaces } from '../../../../../api/controllersApi';
import { FieldProvider } from '../../../../../components/FieldProvider';
import { formatISPProduct } from '../../../../../utils/isp_product';
import { formatISPStatus } from '../../../../../utils/isp_status';
import { FirstAndLastAddresses } from './FirstAndLastAddresses';
import { NO_INTERFACE, NO_PROVIDER, ValidPlanData } from './form_data';

export const FormContent = ({ controllerName }: { controllerName: string }) => {
  const form = useFormikContext<ValidPlanData>();

  const providers =
    useQuery(['providers'], getAllProviders, {
      suspense: true,
    }).data ?? [];

  const interfaces =
    useQuery(
      ['controller', controllerName, 'interfaces'],
      () => getNetworkInterfaces(controllerName),
      {
        suspense: true,
      },
    ).data ?? [];

  return (
    <>
      <FieldContainer>
        <ListItemHeader>
          <ListTitle>Plan details</ListTitle>
        </ListItemHeader>
        <FieldProvider name="provider">
          <SecondaryField
            label="Provider"
            element={
              <BasicSelect>
                <BasicSelectItem key={NO_PROVIDER} value={NO_PROVIDER} disabled>
                  Select a provider
                </BasicSelectItem>
                {providers.map((p) => (
                  <BasicSelectItem key={p.sid} value={p.sid}>
                    {p.name}
                  </BasicSelectItem>
                ))}
              </BasicSelect>
            }
          />
        </FieldProvider>
        <FieldProvider name="product">
          <SecondaryField
            label="Product"
            element={
              <BasicSelect>
                {Object.values(api.ISPProduct).map((product) => (
                  <BasicSelectItem
                    key={product}
                    value={product}
                    disabled={product === api.ISPProduct.IR_UNKNOWN}
                  >
                    {formatISPProduct(product)}
                  </BasicSelectItem>
                ))}
              </BasicSelect>
            }
          />
        </FieldProvider>
        <FieldProvider name="status">
          <SecondaryField
            label="Status"
            element={
              <BasicSelect>
                {Object.values(api.ISPStatus).map((status) => (
                  <BasicSelectItem
                    key={status}
                    value={status}
                    disabled={status === api.ISPStatus.IS_UNKNOWN}
                  >
                    {formatISPStatus(status)}
                  </BasicSelectItem>
                ))}
              </BasicSelect>
            }
          />
        </FieldProvider>
        <FieldProvider name="account_number">
          <SecondaryField label="Account Number" element={<TextInput />} />
        </FieldProvider>
        <SecondaryFieldComposite
          label="Bandwidth"
          fields={
            <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
              <FieldProvider name="download_mbps">
                <CompositeField
                  label="Download"
                  element={<TextInput icon="download" suffix="Mb/s" />}
                />
              </FieldProvider>
              <FieldProvider name="upload_mbps">
                <CompositeField
                  label="Upload"
                  element={<TextInput icon="upload" suffix="Mb/s" />}
                />
              </FieldProvider>
            </div>
          }
        />
        <FieldProvider name="monthly_cost_dollars">
          <SecondaryField label="Price" element={<TextInput prefix="USD" suffix="monthly" />} />
        </FieldProvider>
      </FieldContainer>

      <FieldProvider name="notes">
        <PrimaryField
          label="Notes"
          description="Now accepting newlines! Smash that return key."
          element={<Textarea />}
        />
      </FieldProvider>

      <FieldContainer>
        <PrimaryFieldComposite
          label="IP assignment"
          fields={
            <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
              <div style={{ width: 'fit-content' }}>
                <FieldProvider name="ip_type">
                  <CompositeField
                    label="IP type"
                    element={
                      <BasicSelect>
                        <BasicSelectItem value="dynamic">Dynamic</BasicSelectItem>
                        <BasicSelectItem value="static">Static</BasicSelectItem>
                      </BasicSelect>
                    }
                  />
                </FieldProvider>
              </div>
              {form.values.ip_type === 'static' && (
                <div style={{ flex: 1 }}>
                  <FieldProvider name="static_ip_range">
                    <CompositeField
                      label="IP range"
                      element={<TextInput placeholder="IP range" />}
                    />
                  </FieldProvider>
                </div>
              )}
            </div>
          }
        />
        {form.values.ip_type === 'static' && (
          <>
            <FieldProvider name="gateway_addr">
              <SecondaryField label="Gateway address" element={<TextInput />} />
            </FieldProvider>
            <FieldProvider name="controller_ip">
              <SecondaryField label="Controller address" element={<TextInput />} />
            </FieldProvider>
            <FirstAndLastAddresses ipRange={form.values.static_ip_range} />{' '}
          </>
        )}
      </FieldContainer>

      <FieldProvider name="network_interface">
        <PrimaryField
          label="Network interface"
          element={
            <BasicSelect defaultValue={NO_INTERFACE}>
              <BasicSelectItem value={NO_INTERFACE}>None</BasicSelectItem>
              {interfaces.map((d) => (
                <BasicSelectItem key={d.interface} value={d.interface}>
                  {d.interface}
                </BasicSelectItem>
              ))}
            </BasicSelect>
          }
        />
      </FieldProvider>
    </>
  );
};
