import {
  BasicSelect,
  BasicSelectItem,
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  PrimaryField,
  SecondaryField,
  Textarea,
} from '@meterup/metric';
import { api } from '@meterup/proto';
import { Formik } from 'formik';
import React from 'react';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { CloseDrawerButton } from '../../../../../components/CloseDrawerButton/CloseDrawerButton';
import { FieldProvider } from '../../../../../components/FieldProvider';
import { useCloseDrawerCallback } from '../../../../../hooks/useCloseDrawerCallback';
import { styled } from '../../../../../stitches';
import { InviteUsersFormData, validInviteUsersFormData } from './InviteUsersFormData';

const StyledForm = styled('form', {
  display: 'contents',
});

export const EmailsAndRoleForm = ({
  initialValues,
  onSubmit,
}: {
  initialValues: InviteUsersFormData;
  onSubmit: (values: InviteUsersFormData) => void;
}) => {
  const closeDrawer = useCloseDrawerCallback();
  return (
    <Formik<InviteUsersFormData>
      validationSchema={toFormikValidationSchema(validInviteUsersFormData)}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {(form) => (
        <StyledForm onSubmit={form.handleSubmit}>
          <Drawer>
            <DrawerHeader>
              <DrawerTitle>Add users</DrawerTitle>
              <DrawerControls>
                <CloseDrawerButton />
              </DrawerControls>
            </DrawerHeader>
            <DrawerContent>
              <FieldProvider name="emailListRawText">
                <PrimaryField
                  label="Who else should have access?"
                  element={<Textarea placeholder="Email(s), comma or whitespace separated" />}
                />
              </FieldProvider>
              <FieldProvider name="company_role">
                <SecondaryField
                  label="Choose a role"
                  element={
                    <BasicSelect>
                      <BasicSelectItem value={api.CompanyMembershipRole.guest}>
                        Guest
                      </BasicSelectItem>
                      <BasicSelectItem value={api.CompanyMembershipRole.member}>
                        Member
                      </BasicSelectItem>
                      <BasicSelectItem value={api.CompanyMembershipRole.admin}>
                        Admin
                      </BasicSelectItem>
                    </BasicSelect>
                  }
                />
              </FieldProvider>
            </DrawerContent>
            <DrawerFooter>
              <DrawerControls>
                <Button variant="secondary" type="button" onClick={closeDrawer}>
                  Cancel
                </Button>
                <Button type="submit">Next</Button>
              </DrawerControls>
            </DrawerFooter>
          </Drawer>
        </StyledForm>
      )}
    </Formik>
  );
};
