import { List, ListItemHeader, ListTitle } from '@meterup/metric';
import { api } from '@meterup/proto';
import { orderBy } from 'lodash';
import React from 'react';
import { useQuery } from 'react-query';
import { Column } from 'react-table';

import { fetchDevicesJSON } from '../api/devicesApi';
import { paths } from '../constants';
import { makeLink } from '../utils/makeLink';
import { AutoTable } from './AutoTable/AutoTable';
import { ListItemTableContainer } from './ListItemTableContainer';
import { ListItemViewAllLink } from './ListItemViewAllLink';
import { NeutralBadge, PositiveBadge } from './Network/badges';
import { ListItemStatList, StatItem, StatItemLabel } from './StatItemLabel';

const columns: Column<api.AccessPointRadiosResponse>[] = [
  {
    Header: 'Name',
    accessor: (d) => d.access_point?.name,
  },
  {
    Header: 'Clients',
    accessor: (d) => d.access_point?.connected_clients,
  },
];

export const AccessPointsWidget = ({ controllerName }: { controllerName: string }) => {
  const devices =
    useQuery(
      ['controller', controllerName, 'access_points'],
      async () => fetchDevicesJSON(controllerName),
      { suspense: true },
    ).data ?? [];

  const orderedAndLimitedDevices = orderBy(devices, (d) => d.access_point?.connected_clients).slice(
    0,
    5,
  );

  const onlineCount = devices.filter((d) => d.access_point?.status === 'online').length;
  const offlineCount = devices.filter((d) => d.access_point?.status !== 'online').length;

  return (
    <List>
      <ListItemHeader>
        <ListTitle>Access points</ListTitle>
      </ListItemHeader>
      <ListItemStatList>
        <StatItem>
          <PositiveBadge size="large" icon="device" arrangement="leading-icon">
            {onlineCount}
          </PositiveBadge>
          <StatItemLabel>Online</StatItemLabel>
        </StatItem>
        <StatItem>
          <NeutralBadge size="large" icon="device" arrangement="leading-icon">
            {offlineCount}
          </NeutralBadge>
          <StatItemLabel>Offline</StatItemLabel>
        </StatItem>
      </ListItemStatList>
      <ListItemTableContainer>
        <AutoTable columns={columns} data={orderedAndLimitedDevices} shouldShowTopBar={false} />
      </ListItemTableContainer>
      <ListItemViewAllLink to={makeLink(paths.pages.ControllerDevicesList, { controllerName })}>
        View all{' '}
        {orderedAndLimitedDevices.length < devices.length
          ? `(Showing ${orderedAndLimitedDevices.length} out of ${devices.length})`
          : ''}
      </ListItemViewAllLink>
    </List>
  );
};
