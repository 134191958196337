import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@meterup/metric';
import { Formik } from 'formik';
import { DateTime } from 'luxon';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { fetchIncidents, updateIncident } from '../../../../api/controllersApi';
import { CloseDrawerButton } from '../../../../components/CloseDrawerButton/CloseDrawerButton';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { getErrorMessage, ResourceNotFoundError } from '../../../../errors/errors';
import { styled } from '../../../../stitches';
import { checkDefinedOrThrow, expectDefinedOrThrow } from '../../../../utils/expectDefinedOrThrow';
import { isDefined } from '../../../../utils/isDefined';
import { makeDrawerLink } from '../../../../utils/makeLink';
import { EditFormContent } from './common/EditFormContent';
import {
  DURATION,
  toIncidentUpdateDurationRequest,
  ValidIncidentData,
  validIncidentData,
} from './common/form_data';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/incidents/:id/edit-duration',
});

const StyledForm = styled('form', {
  display: 'contents',
});

export default function IncidentEditDuration() {
  const navigate = useNavigate();

  const { controllerName, id } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.IncidentEditDuration),
  );

  const incident = useQuery(['controller', controllerName], () => fetchIncidents(controllerName), {
    suspense: true,
  }).data?.find((res) => res.sid === id);

  expectDefinedOrThrow(incident, new ResourceNotFoundError('Incident not found'));

  const queryClient = useQueryClient();

  const createDrawerMutation = useMutation(
    async (data: ValidIncidentData) => {
      const apiData = toIncidentUpdateDurationRequest(data);
      return updateIncident(controllerName, id, apiData.incidentData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['controllers', controllerName, 'incidents']);
        navigate(
          makeDrawerLink(paths.drawers.IncidentDetail, {
            controllerName,
            id,
          }),
        );
      },
    },
  );

  return (
    <Formik<ValidIncidentData>
      initialValues={{
        start_time: DateTime.fromISO(incident.start_time ? incident.start_time : '').toFormat(
          'yyyy-MM-dd HH:mm:ss',
        ),
        end_time: DateTime.fromISO(incident.end_time ? incident.end_time : '').toFormat(
          'yyyy-MM-dd HH:mm:ss',
        ),
      }}
      validationSchema={toFormikValidationSchema(validIncidentData)}
      onSubmit={(values) => createDrawerMutation.mutate(values)}
    >
      {(form) => (
        <StyledForm onSubmit={form.handleSubmit}>
          <Drawer>
            <DrawerHeader>
              <DrawerTitle>Edit duration</DrawerTitle>
              <DrawerControls>
                <CloseDrawerButton />
              </DrawerControls>
            </DrawerHeader>

            <DrawerContent>
              {isDefined(createDrawerMutation.error) && (
                <Alert
                  heading="Error while submitting"
                  copy={getErrorMessage(createDrawerMutation.error)}
                />
              )}
              <EditFormContent page={DURATION} />
            </DrawerContent>

            <DrawerFooter>
              <DrawerControls>
                <Button
                  type="button"
                  variant="secondary"
                  as={Link}
                  to={makeDrawerLink(paths.drawers.IncidentDetail, {
                    controllerName,
                    id,
                  })}
                >
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </DrawerControls>
            </DrawerFooter>
          </Drawer>
        </StyledForm>
      )}
    </Formik>
  );
}
