import React from 'react';
import { PagefileMetaFn } from 'vite-plugin-pagefiles';

import Stats from '../../../components/Stats/Stats';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/stats',
});

export default function ControllerStats() {
  return <Stats />;
}
