// General API setup and calls should be here
// Specific Networks, Devices and Clients API should be in their own file

import { api } from '@meterup/proto';

import { makeAPICall } from './api_calls';
import { axiosInstanceJSON } from './apiClients';

export const getIdentity = async () =>
  makeAPICall(async () => {
    const result = await axiosInstanceJSON.get<api.IdentityResponse>('/v1/identity');
    return result.data;
  });
