import { ExtractRouteParams } from '@meterup/react-router-extensions';
import { generatePath } from 'react-router';

import { Nav } from '../components/Nav';

export const makeLink = <T extends string>(path: T, args: ExtractRouteParams<T, string>): string =>
  // @ts-expect-error
  generatePath(path, args);

export const makeDrawerLink = <T extends string>(path: T, args: ExtractRouteParams<T, string>) =>
  Nav.makeTo({ root: window.location.pathname, drawer: `${makeLink(path, args)}` });

export const makeCloseDrawerLink = () =>
  Nav.makeTo({ root: window.location.pathname, drawer: null });
