import { Body2, Button, HStack, Icon, space } from '@meterup/metric';
import React from 'react';
import { Link as ReactRouterLink, Route } from 'react-router-dom';

import { Nav } from '../components/Nav';
import { PageControls, PageHeader } from '../components/Page';
import { paths } from '../constants';
import { useConfigEditor } from '../context/ConfigEditorContext';
import { colors, styled } from '../stitches';
import { checkDefinedOrThrow } from '../utils/expectDefinedOrThrow';
import { makeLink } from '../utils/makeLink';

const UnsavedText = styled(Body2, {
  color: colors['gray-600'],
});

const ErrorText = styled(Body2, {
  color: colors['red-700'],
});

const SaveChangesFooter = () => {
  const config = useConfigEditor();

  return config.isDraftModified ? (
    <PageHeader>
      {config.isDraftTextValidJSON ? (
        <UnsavedText>Unsaved changes</UnsavedText>
      ) : (
        <HStack align="center" spacing={space(8)}>
          <Icon icon="warning" color={{ light: 'red-600' }} />
          <ErrorText>The editor contains invalid JSON</ErrorText>
          <Button variant="secondary" size="small" onClick={config.resetDraftTextToModel}>
            Revert editor to last valid state
          </Button>
        </HStack>
      )}
      <PageControls>
        <Button key="cancel" variant="secondary" onClick={config.resetDraft}>
          Discard changes
        </Button>
        <Button
          onClick={config.saveDraftModel}
          loading={config.isSaving}
          disabled={!config.isDraftTextValidJSON}
        >
          Save changes
        </Button>
      </PageControls>
    </PageHeader>
  ) : null;
};

const ReviewChangesFooter = () => {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerDetails),
  );

  const config = useConfigEditor();

  return config.isDraftModified ? (
    <PageHeader>
      <UnsavedText>Unsaved changes</UnsavedText>
      <PageControls>
        <Button key="cancel" variant="secondary" onClick={config.resetDraft}>
          Discard changes
        </Button>
        <Button
          as={ReactRouterLink}
          to={makeLink(paths.pages.ControllerConfig, { controllerName })}
        >
          Review changes
        </Button>
      </PageControls>
    </PageHeader>
  ) : null;
};

export const ControllerPageFooter = () => (
  <Nav.Routes regionName="root">
    <Route path={paths.pages.ControllerConfig} element={<SaveChangesFooter />} />
    <Route path="*" element={<ReviewChangesFooter />} />
  </Nav.Routes>
);
