// NOTE: Copied from the dashboard repository

import { Field } from 'formik';
import { FieldConfig } from 'formik/dist/Field';
import React from 'react';

import { Paths, TypeAtPath } from './paths';

/**
 * Enables type-safe form fields using Formik's nested object path syntax.
 *
 * Due to limitations with how TypeScript infers generic parameters types, this helper is
 * implemented as a factory function that captures the Formik form's Values type and returns a
 * component.
 */
export const createTypedField = <Values extends object>() =>
  function TypedField<Name extends Paths<Values>>(
    props: {
      // eslint-disable-next-line react/no-unused-prop-types
      name: Name;
    } & FieldConfig<TypeAtPath<Values, Name>>,
  ) {
    return React.createElement(Field, props);
  };
