import { mboot } from '@meterup/proto';

import { NetworkInfoResponseData } from '../api/types';

export enum LegacyNetworkPSKSchemeType {
  Static = 'static',
  Rotating = 'rotating',
}

export interface LegacyNetworkInfo {
  key: string;
  ssid: string;
  password: string;
  rotationStrategy: mboot.GuestPasswordStrategy | null | undefined;
  isCustomKey: boolean;
  isSSIDDisabled: boolean;
  passwordScheme: LegacyNetworkPSKSchemeType;
}

export function getPasswordScheme(data: NetworkInfoResponseData) {
  return data.guest_strategy === mboot.GuestPasswordStrategy.UNKNOWN ||
    data.guest_strategy === mboot.GuestPasswordStrategy.NEVER
    ? LegacyNetworkPSKSchemeType.Static
    : LegacyNetworkPSKSchemeType.Rotating;
}

const KNOWN_NETWORK_KEYS = ['private', 'private_2g', 'guest'];

function isCustomNetworkKey(key: string) {
  return !KNOWN_NETWORK_KEYS.includes(key);
}

function getNetworkKeys(data: NetworkInfoResponseData): string[] {
  return Object.keys(data)
    .filter((key) => key.endsWith('_ssid'))
    .map((key) => key.replace(/_ssid$/, ''));
}

function getLegacyNetworkInfoWithKey(
  data: NetworkInfoResponseData,
  key: string,
): LegacyNetworkInfo {
  return {
    key,
    ssid: data[`${key}_ssid`] ?? '',
    password: (key === 'private_2g' ? data.private_password : data[`${key}_password`]) ?? '',
    rotationStrategy: data.guest_strategy,
    isCustomKey: isCustomNetworkKey(key),
    isSSIDDisabled: data[`${key}_ssid_is_disabled`] ?? false,
    passwordScheme: key === 'guest' ? getPasswordScheme(data) : LegacyNetworkPSKSchemeType.Static,
  };
}

export function networkInfoAPIResponseToList(data: NetworkInfoResponseData): LegacyNetworkInfo[] {
  return getNetworkKeys(data)
    .map((key) => getLegacyNetworkInfoWithKey(data, key))
    .sort((a, b) => {
      if (a.isCustomKey && !b.isCustomKey) {
        return 1;
      }

      if (!a.isCustomKey && b.isCustomKey) {
        return -1;
      }

      if (a.key.startsWith('private') && !b.key.startsWith('private')) {
        return -1;
      }

      if (!a.key.startsWith('private') && b.key.startsWith('private')) {
        return 1;
      }

      return a.key.localeCompare(b.key);
    });
}
