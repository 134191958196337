import { Drawer, DrawerControls, DrawerHeader, DrawerTitle } from '@meterup/metric';
import { mboot } from '@meterup/proto';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { PagefileMetaFn } from 'vite-plugin-pagefiles';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { fetchNetworkInfoJSON, updateNetworkInfoJSON } from '../../../../api/controllersApi';
import { CloseDrawerButton } from '../../../../components/CloseDrawerButton/CloseDrawerButton';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { ControllerRequestFailedError } from '../../../../errors/errors';
import { styled } from '../../../../stitches';
import { checkDefinedOrThrow, expectDefinedOrThrow } from '../../../../utils/expectDefinedOrThrow';
import { getPasswordScheme } from '../../../../utils/legacy_network_info';
import { makeDrawerLink } from '../../../../utils/makeLink';
import LegacyNetworkInfoForm from './LegacyNetworkInfoForm';
import ReviewLegacyNetworkInfoForm from './ReviewLegacyNetworkInfoForm';
import {
  formDataToAPIData,
  ValidLegacyNetworkInfoFormData,
  validLegacyNetworkInfoFormData,
} from './ValidLegacyNetworkInfoFormData';

const StyledForm = styled('form', {
  display: 'contents',
});

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/legacy-ssids/edit',
});

export default function EditLegacyNetworkInfo() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.LegacyNetworkInfo),
  );

  const [isReviewing, setIsReviewing] = useState(false);

  const networkPass = useQuery(
    ['controllers', controllerName, 'legacy-network-info'],
    async () => fetchNetworkInfoJSON(controllerName),
    {
      suspense: true,
    },
  ).data;

  expectDefinedOrThrow(
    networkPass,
    new ControllerRequestFailedError(controllerName, 'network_info'),
  );

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const updateSSIDMutation = useMutation(
    async (values: ValidLegacyNetworkInfoFormData) => {
      const apiData = formDataToAPIData(values);
      await updateNetworkInfoJSON(controllerName, apiData);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('controllers');
        navigate(makeDrawerLink(paths.drawers.LegacyNetworkInfo, { controllerName }));
      },
    },
  );

  return (
    <Formik<ValidLegacyNetworkInfoFormData>
      validationSchema={toFormikValidationSchema(validLegacyNetworkInfoFormData)}
      initialValues={{
        private_ssid: networkPass.private_ssid ?? '',
        private_2g_ssid: networkPass.private_2g_ssid ?? '',
        private_password: networkPass.private_password ?? '',
        guest_ssid: networkPass.guest_ssid ?? '',
        guest_password: networkPass.guest_password,
        guest_strategy: networkPass.guest_strategy ?? mboot.GuestPasswordStrategy.NEVER,
        guest_psk_scheme: getPasswordScheme(networkPass),
      }}
      onSubmit={(v) => updateSSIDMutation.mutate(v)}
    >
      {(form) => (
        <StyledForm onSubmit={form.handleSubmit}>
          <Drawer>
            <DrawerHeader>
              <DrawerTitle>Wireless networks</DrawerTitle>
              <DrawerControls>
                <CloseDrawerButton />
              </DrawerControls>
            </DrawerHeader>
            {isReviewing ? (
              <ReviewLegacyNetworkInfoForm
                mutationResult={updateSSIDMutation as UseMutationResult}
                onClickBack={() => setIsReviewing(false)}
              />
            ) : (
              <LegacyNetworkInfoForm
                controllerName={controllerName}
                onClickReview={() => setIsReviewing(true)}
              />
            )}
          </Drawer>
        </StyledForm>
      )}
    </Formik>
  );
}
