import { Body2, CopyCapsule, Heading2, Tab, Tabs } from '@meterup/metric';
import React from 'react';
import { useQuery } from 'react-query';
import { Link as ReactRouterLink } from 'react-router-dom';

import { fetchCompanyJSON } from '../api/companyApi';
import { fetchControllerJSON } from '../api/controllersApi';
import { Nav } from '../components/Nav';
import { paths } from '../constants';
import { useCanUseConfigAPIForController } from '../hooks/useCanUseConfigAPIForController';
import { useIsPathActiveFn } from '../hooks/useIsPathActiveFn';
import { HeaderContainer } from '../layouts/ControllerPageLayout';
import { colors, fontWeights, styled } from '../stitches';
import { checkDefinedOrThrow, expectDefinedOrThrow } from '../utils/expectDefinedOrThrow';
import { isDefined, isDefinedAndNotEmpty } from '../utils/isDefined';
import { makeLink } from '../utils/makeLink';
import { ControllerStatusIcon } from './ControllerStatusIcon';

const Container = styled(HeaderContainer, {
  padding: 0,
  vStack: '$8',
  alignItems: 'stretch',
});

const ControllerEphemera = styled('div', {
  marginLeft: '$20',
  marginRight: '$20',
  hStack: '$4',
  paddingTop: '$12',
  paddingBottom: 7,
  borderBottom: `1px solid ${colors['gray-100']}`,
});

const CompanyLink = styled(ReactRouterLink, Body2, {
  color: colors['blue-600'],
  fontWeight: fontWeights.medium,
  '&:hover': {
    textDecoration: 'underline',
  },
});

const ControllerAddress = styled(Body2, {
  color: colors['gray-600'],
});

const HeaderContent = styled('div', {
  hStack: '$12',
  overflow: 'auto',
  paddingX: '$20',
  paddingBottom: '$12',
});

const HeadingAndTabs = styled('div', { vStack: '$4', alignItems: 'stretch' });

export const ControllerPageHeader = () => {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerDetails),
  );

  const isPathActive = useIsPathActiveFn();

  const controllerData = useQuery(
    ['controllers', controllerName],
    () => fetchControllerJSON(controllerName),
    {
      suspense: true,
    },
  ).data;

  expectDefinedOrThrow(controllerData);

  const companyData = useQuery(
    ['companies', controllerName],
    () => fetchCompanyJSON(controllerData.company_slug),
    {
      suspense: true,
      enabled: isDefinedAndNotEmpty(controllerData.company_slug),
    },
  ).data;

  const canShowConfigUI = useCanUseConfigAPIForController(controllerName);

  return (
    <Container>
      {(isDefined(companyData) || isDefinedAndNotEmpty(controllerData.address)) && (
        <ControllerEphemera>
          {isDefined(companyData) && (
            <CompanyLink
              to={Nav.makeTo({
                root: makeLink(paths.pages.CompaniesList, {}),
                drawer: makeLink(paths.drawers.CompanySummary, {
                  companyName: controllerData.company_slug,
                }),
              })}
            >
              {companyData.name}
            </CompanyLink>
          )}
          {isDefinedAndNotEmpty(controllerData.address) && (
            <ControllerAddress>{controllerData.address}</ControllerAddress>
          )}
        </ControllerEphemera>
      )}

      <HeaderContent>
        <ReactRouterLink to={makeLink(paths.pages.ControllerDetails, { controllerName })}>
          <ControllerStatusIcon value={controllerData.status} />
        </ReactRouterLink>
        <HeadingAndTabs>
          <Heading2>
            <CopyCapsule
              textValue={controllerName}
              aria-label="Copy controller name"
              arrangement="leading-label"
            >
              {controllerName}
            </CopyCapsule>
          </Heading2>
          <Tabs>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerDetails, { controllerName })}
              active={isPathActive(paths.pages.ControllerDetails, { end: true })}
              icon="information"
            >
              Details
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerDevicesList, { controllerName })}
              active={isPathActive(paths.pages.ControllerDevicesList)}
              icon="device"
            >
              Devices
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerClientsList, { controllerName })}
              active={isPathActive(paths.pages.ControllerClientsList)}
              icon="client"
            >
              Clients
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerServicePlans, { controllerName })}
              active={isPathActive(paths.pages.ControllerServicePlans)}
              icon="wired"
            >
              ISPs
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerLogs, { controllerName })}
              active={isPathActive(paths.pages.ControllerLogs)}
              icon="log"
            >
              Logs
            </Tab>
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerStats, { controllerName })}
              active={isPathActive(paths.pages.ControllerStats)}
              icon="reporting"
            >
              Stats
            </Tab>
            {canShowConfigUI && (
              <>
                <Tab
                  as={ReactRouterLink}
                  to={makeLink(paths.pages.ControllerConfig, { controllerName })}
                  active={isPathActive(paths.pages.ControllerConfig)}
                  icon="wrench"
                >
                  Config
                </Tab>
                <Tab
                  as={ReactRouterLink}
                  to={makeLink(paths.pages.ControllerState, { controllerName })}
                  active={isPathActive(paths.pages.ControllerState)}
                  icon="pulse"
                >
                  State
                </Tab>
              </>
            )}
            <Tab
              as={ReactRouterLink}
              to={makeLink(paths.pages.ControllerIncidentsList, { controllerName })}
              active={isPathActive(paths.pages.ControllerIncidentsList)}
              icon="warning"
            >
              Incidents
            </Tab>
          </Tabs>
        </HeadingAndTabs>
      </HeaderContent>
    </Container>
  );
};
