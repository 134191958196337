import { freeze, immerable } from 'immer';

import { ct } from '../types/config/config';

export class MeterV1NetworkVLAN {
  [immerable] = true;

  private constructor(
    public ephemeralId: string,
    public name: string,
    public json: ct.MeterV1NetworkVLAN,
  ) {}

  static fromJSON(name: string, json: ct.MeterV1NetworkVLAN): MeterV1NetworkVLAN {
    return freeze(new MeterV1NetworkVLAN(name, name, json), true);
  }

  toJSON(): ct.MeterV1NetworkVLAN {
    return this.json;
  }
}
