/* eslint-disable max-classes-per-file */
export interface DisplayableError {
  displayTitle: string;
  displayMessage?: string;
}

export const isDisplayableError = (v: any): v is DisplayableError =>
  typeof v === 'object' &&
  v !== null &&
  'displayTitle' in v &&
  typeof (v as { displayTitle: unknown }).displayTitle === 'string';

export interface WrappedError {
  originalError?: unknown;
}

export const isWrappedError = (v: any): v is WrappedError => v.originalError !== undefined;

export const isDisplayableWrappedError = (v: any): v is DisplayableError & WrappedError =>
  isDisplayableError(v) && isWrappedError(v);

export const getErrorMessage = (error: unknown) => {
  if (isDisplayableError(error)) {
    return error.displayMessage;
  }

  if (error instanceof Error) {
    return error.message;
  }

  return 'Unknown error';
};

export const getErrorTitle = (error: unknown) => {
  if (isDisplayableError(error)) {
    return error.displayTitle;
  }

  if (error instanceof Error) {
    return error.name;
  }

  return 'Unknown error';
};

export class ResourceNotFoundError extends Error implements DisplayableError {
  readonly displayTitle = 'Resource not found';

  constructor(readonly displayMessage: string) {
    super(displayMessage);
    this.name = 'ResourceNotFoundError';
  }
}

export class UnexpectedError extends Error implements DisplayableError, WrappedError {
  readonly displayTitle = 'Unexpected error';

  constructor(readonly displayMessage: string, readonly originalError?: unknown) {
    super(displayMessage);
    this.name = 'UnexpectedError';
  }
}

export class ApplicationError extends Error implements DisplayableError, WrappedError {
  readonly displayTitle = 'Application error';

  constructor(readonly displayMessage: string, readonly originalError?: unknown) {
    super(displayMessage);
    this.name = 'UnexpectedError';
  }
}
export class ControllerRequestFailedError extends Error implements DisplayableError, WrappedError {
  readonly displayTitle = 'Controller request failed';

  readonly displayMessage = `The controller request ${this.requestName} for controller ${this.controllerName} failed. The controller may be offline.`;

  constructor(
    readonly controllerName: string,
    readonly requestName: string,
    readonly originalError?: unknown,
  ) {
    super('Controller request failed');
    this.name = 'ControllerRequestFailedError';
  }
}

export class NoConnectionError extends Error implements DisplayableError, WrappedError {
  readonly displayTitle = 'No connection to server';

  readonly displayMessage = 'Request was sent but no response was received from server';

  constructor(readonly originalError?: unknown) {
    super('Request was sent but no response was received from server');
    this.name = 'NoConnectionError';
  }
}
