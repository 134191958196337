import {
  Theme,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from '@material-ui/core/styles';

const muiTheme = createMuiTheme({
  colors: {
    dark: '#001729',
    darkBlue: '#525f7f',
    lightBlue: '#ced0ec85',
    purple: '#3F51B5',
    lightGray: '#b7b9be',
    lighterGray: '#eeeeee',
    paleGray: '#FAFAFA',
    white: '#ffffff',
    mainBackground: '#FCFCFC',
    online: '#36CA8E',
    paleGreen: '#EBFAF5',
    offline: '#FF1212',
    paleRed: '#FFA8A8',
    draft: '#BFBF3A',
    lightYellow: '#FFFFD6',
    warning: '#ffc107',
    success: 'green',
  },
  shadow: '0 14px 28px 0 rgba(82,95,127,.15)',
  palette: {
    primary: {
      main: '#3F51B5',
      contrastText: '#FFFFFF',
    },
    background: {
      lightEmphasis: 'rgb(240, 242, 247)',
      paper: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: ['Rotunda-Meter', 'Work Sans', 'sans-serif'],
    button: {
      textTransform: 'inherit',
    },
  },
  overrides: {
    MuiTypography: {
      body1: {
        fontSize: '0.8rem',
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: '50px !important',
      },
    },
    MuiPaper: {
      root: {
        color: '#525f7f',
      },
      rounded: {
        borderRadius: 10,
      },
      elevation1: {
        boxShadow: '0 14px 28px 0 rgba(82,95,127,.15)',
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid #E4E7ED',
        fontWeight: 200,
      },
      body: {
        color: '#525f7f',
      },
    },
    MuiButton: {
      root: {
        color: '#525f7f',
        fontWeight: 400,
      },
      text: {
        padding: '2px 6px',
      },
    },
    MuiInputBase: {
      input: {
        color: '#525f7f',
        padding: '4px 0px 4px',
        fontSize: 14,
      },
    },
    MuiInputLabel: {
      animated: {
        fontSize: 13,
      },
      shrink: {
        transform: 'translate(0, 7.5px) scale(0.75)',
      },
    },
    MuiInput: {
      underline: {
        '&&&&:hover:before': {
          borderBottom: '1px solid #525f7f',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        fontWeight: '200',
      },
    },
  },
} as any) as Theme & { colors: Record<string, string> };

export default muiTheme;
