import { api } from '@meterup/proto';
import { AxiosResponse } from 'axios';

import { getIdentity } from './api';
import { getMany, getOne, mutateVoid } from './api_calls';
import { axiosInstanceJSON } from './apiClients';

export const fetchCompanyUsersJSON = async (companyName: string) =>
  getMany(async () => {
    const results = await axiosInstanceJSON.get<api.UsersResponse>(
      `/v1/companies/${companyName}/users`,
    );
    return results.data.users;
  });

export const fetchCompanyUserJSON = async (companyName: string, userSid: string) =>
  getOne(async () => {
    const results = await axiosInstanceJSON.get<api.UserResponse>(
      `/v1/companies/${companyName}/users/${userSid}`,
    );
    return results.data;
  });

export const deleteUser = async (companySlug: string, sid: string) =>
  mutateVoid(async () => {
    await axiosInstanceJSON.delete(`/v1/companies/${companySlug}/users/${sid}`);
  });

export const createUserJSON = async (
  companySlug: string,
  // TODO: AETM-482
  data: Omit<api.UserCreateRequest, 'role'>,
) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.post<
      api.UserCreateRequest,
      AxiosResponse<api.UserResponse>
    >(`/v1/companies/${companySlug}/users`, data);
    return result.data;
  });

export const updateUserJSON = async (
  companySlug: string,
  userSid: string,
  data: api.UserUpdateRequest,
) =>
  getOne(async () => {
    const result = await axiosInstanceJSON.put<
      api.UserUpdateRequest,
      AxiosResponse<api.UserResponse>
    >(`/v1/companies/${companySlug}/users/${userSid}`, data);
    return result.data;
  });

export const addNOCUserToCompany = async (companySlug: string) =>
  mutateVoid(async () => {
    const identity = await getIdentity();
    if (identity) {
      await createUserJSON(companySlug, {
        email: identity.username,
        company_role: api.CompanyMembershipRole.admin,
      });
    }
  });
