import React from 'react';
import { PagefileMetaFn } from 'vite-plugin-pagefiles';

import { Nav } from '../../../components/Nav';
import EventLogsNetworkWidget from '../../../components/Network/EventLogsNetwork/EventLogsNetworkWidget';
import { paths } from '../../../constants';
import { checkDefinedOrThrow } from '../../../utils/expectDefinedOrThrow';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/logs',
});

export default function ControllerLogs() {
  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerLogs),
  );

  return <EventLogsNetworkWidget controllerName={controllerName} />;
}
