import { makeStyles } from '@material-ui/core/styles';

export const statsStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  widgetContainer: {
    border: `1px solid ${theme.colors.lightBlue}`,
    borderRadius: 6,
    cursor: 'pointer',
    display: 'flex',
    flex: '1 0 0',
    minHeight: 115,
    minWidth: 190,
    flexDirection: 'column',
    transitionProperty: 'transform, box-shadow',
    transitionDuration: '100ms, 400ms',
    transitionTimingFunction: 'ease-out, ease-out',
    '& h3': {
      margin: 0,
      padding: 0,
      fontSize: '0.9em',
      fontWeight: 400,
    },
    '& span': {
      fontSize: '0.8em',
    },
    '&:hover': {
      boxShadow: theme.shadow,
      transform: 'translate(0px, -2px)',
      '& goToDevice': {
        opacity: 0.6,
      },
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 250,
    },
  },
  simpleWidgetContainer: {
    border: `1px solid ${theme.colors.lightBlue}`,
    borderRadius: 6,
    display: 'flex',
    flex: '1 0 0',
    margin: '.5rem',
    minHeight: 115,
    minWidth: 190,
    flexDirection: 'column',
    '& h3': {
      margin: 0,
      padding: 0,
      fontSize: '0.9em',
      fontWeight: 400,
    },
    '& span': {
      fontSize: '0.8em',
    },
  },
  statsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  widgetBody: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0.5em 1em',
    margin: 'auto 0',
  },
  goToDevice: {
    fontSize: 16,
  },
  widget: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dotOn: {
    fontSize: 12,
    color: theme.colors.online,
    marginRight: 5,
  },
  dotOff: {
    fontSize: 12,
    color: theme.colors.paleRed,
    marginRight: 5,
  },
  iconText: {
    display: 'flex',
  },
  lastUpdated: {
    fontSize: 12,
  },
  // password&settings
  widgetNoLinkContainer: {
    border: `1px solid ${theme.colors.lightBlue}`,
    borderRadius: 6,
    display: 'flex',
    flex: '1 0 0',
    minWidth: 330,
    flexDirection: 'column',
    paddingBottom: 8,
    transitionProperty: 'transform, box-shadow',
    transitionDuration: '100ms, 400ms',
    transitionTimingFunction: 'ease-out, ease-out',
    '& h3': {
      fontSize: '0.9em',
      fontWeight: 400,
      margin: 0,
      padding: 0,
    },
    '& p': {
      margin: '5px 1px',
      fontSize: '0.8em',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 250,
    },
  },
  tableTitle: {
    alignItems: 'center',
    borderBottom: '1px solid rgb(236, 237, 255)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 35,
    padding: '0 1em',
  },
  tableBody: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0 0.5em',
    padding: '0.5em',
    '&:hover': {
      borderRadius: 6,
      backgroundColor: theme.colors.paleGray,
    },
    '&:last-child': {
      border: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  ssidItem: {
    display: 'flex',
    '& span': {
      marginLeft: 3,
      marginTop: '5px',
      textTransform: 'lowercase',
      fontSize: '0.8rem',
      fontWeight: 300,
    },
  },

  // ISPs Widget
  ISPWidgetBody: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 'auto 0',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  provider: {
    paddingLeft: ' 1.5em',
  },
  widgetShortContainer: {
    cursor: 'pointer',
    border: `1px solid ${theme.colors.lightBlue}`,
    borderRadius: 6,
    display: 'flex',
    flex: '1 0 0',
    maxWidth: 180,
    minWidth: 300,
    minHeight: 115,
    marginRight: 26,
    flexDirection: 'column',
    transitionProperty: 'transform, box-shadow',
    transitionDuration: '100ms, 400ms',
    transitionTimingFunction: 'ease-out, ease-out',
    '&:last-child': {
      margin: 0,
    },
    '& h3': {
      margin: 0,
      padding: 0,
      fontSize: '0.9em',
      fontWeight: 400,
    },
    '& span': {
      fontSize: '0.8em',
    },
    '&:hover': {
      boxShadow: theme.shadow,
      transform: 'translate(0px, -2px)',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 250,
    },
  },
  titleIcon: {
    alignItems: 'center',
    display: 'flex',
    '& svg': {
      paddingRight: 6,
    },
    '& h3': {
      marginTop: 4,
    },
  },
  widgetIcon: {
    width: 18,
    height: 18,
    paddingRight: 6,
  },
  widgetIconUser: {
    width: 14,
    height: 14,
    paddingRight: 6,
  },
  widgetTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 35,
    alignItems: 'center',
    padding: '0 1em',
  },
  actionBtnIcon: {
    minWidth: 20,
    padding: '0px 6px',
    marginLeft: 4,
  },
  divSpace: {
    minWidth: 20,
    padding: '0px 6px',
    marginLeft: 4,
  },
  sectionPassword: {
    display: 'flex',
    minWidth: 189,
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentContainer: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: theme.shadows[5],
    marginTop: 35,
    maxWidth: '25%',
    padding: '10px 20px',
    width: '100%',
    outline: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: 'calc(100vh - 127px)',
    [theme.breakpoints.down('sm')]: {
      marginTop: 46,
      overflowY: 'auto',
      maxWidth: '80%',
      width: '80%',
    },
    [theme.breakpoints.down('xs')]: {
      height: '85vh',
      marginTop: 46,
      overflowY: 'auto',
      maxWidth: '80%',
      width: '80%',
    },
  },
  contentContainerConfirmation: {
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    maxWidth: 555,
    outline: 0,
    padding: '30px 30px 40px 30px',
    textAlign: 'center',
    width: '50%',
    '& p': {
      fontWeight: 400,
      fontSize: 16,
    },
    '& span': {
      fontSize: 14,
    },
  },
  buttonsContainer: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginTop: 35,
  },
  continueConfirmationButton: {
    backgroundColor: theme.colors.darkBlue,
    color: theme.colors.white,
    cursor: 'pointer',
    padding: '5px 20px',
    border: `1px solid ${theme.colors.darkBlue}`,
    marginLeft: 35,
    opacity: 1,
    '&.Mui-disabled': {
      opacity: 0.7,
    },
    '&:hover': {
      backgroundColor: theme.colors.darkBlue,
      opacity: 0.7,
      cursor: 'pointer',
    },
  },
  cancelConfirmationButton: {
    backgroundColor: 'transparent',
    border: `1px solid ${theme.colors.darkBlue}`,
    color: theme.colors.darkBlue,
    cursor: 'pointer',
    padding: '5px 20px',
    marginRight: 35,
    opacity: 1,
    '&:hover': {
      backgroundColor: theme.colors.lightBlue,
      opacity: 0.7,
      cursor: 'pointer',
    },
  },
  labelConfirmation: {
    fontSize: 11,
    marginTop: 4,
  },
  labelData: {
    fontStyle: 'italic',
  },
  form: {
    flex: 1,
    marginBottom: '1em',
  },
  formTitle: {
    fontWeight: 400,
    fontSize: 16,
    margin: '9px 0px 4px',
  },
  column: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '20px 0',
  },
  formColumnBtn: {
    display: 'flex',
    justifyContent: 'end',
    gap: 8,
  },
  margin: {
    margin: '20px 0',
  },
  formControl: {
    minWidth: '30%',
    '& .MuiInput-formControl': {
      fontWeight: 200,
    },
    '& .Mui-disabled:hover:before, &.Mui-disabled:hover:before': {
      borderBottomStyle: 'dotted !important',
    },
    '&.Mui-disabled .Mui-disabled': {
      opacity: '0.7',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  formLabel: {
    padding: '0 10px 4px 0',
    display: 'flex',
    alignItems: 'baseline',
    fontSize: '0.6em',
  },
  formField: {
    width: '100%',
    marginBottom: 4,
    flex: 1,
    '& .MuiInput-formControl': {
      fontWeight: 200,
    },
  },
  errorMessage: {
    fontSize: 14,
    color: 'red',
  },
  saveBtn: {
    backgroundColor: theme.colors.darkBlue,
    display: 'inline-block',
    color: 'white !important',
    cursor: 'pointer',
    padding: '5px 20px',
    '&:hover': {
      backgroundColor: theme.colors.darkBlue,
    },
    '&:disabled': {
      backgroundColor: theme.colors.lightBlue,
    },
  },
  cancelBtn: {
    padding: '5px 20px',
    backgroundColor: 'transparent',
    border: `1px solid ${theme.colors.darkBlue}`,
    display: 'inline-block',
    color: `${theme.colors.darkBlue} !important`,
    cursor: 'pointer',
  },
  tableRow: {
    paddingTop: '0.5em',
  },
  boxTable: {
    width: '100%',
  },
  boxEmpty: {
    paddingLeft: '1.5em',
  },
  widgetBodyTable: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    minHeight: 150,
    justifyContent: 'space-between',
    margin: 'auto 0',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  banner: {
    fontSize: '12px',
    color: 'red',
    margin: '8px 8px 0',
  },
  passwordItem: {},
  actionIcon: {},
  rotation: {},
  tooltip: {},
  formText: {},
  formBox: {},
  eventType: {},
  message: {},
  createdAt: {},
}));
