import { Button, HStack, Link, space } from '@meterup/metric';
import { api } from '@meterup/proto';
import React from 'react';
import { useQuery } from 'react-query';
import { Link as RouterLink } from 'react-router-dom';
import { CellProps, Column } from 'react-table';
import { PagefileMetaFn } from 'vite-plugin-pagefiles';

import { fetchCompanyUsersJSON } from '../../../api/usersApi';
import { AutoTable } from '../../../components/AutoTable/AutoTable';
import { Nav } from '../../../components/Nav';
import { UserRoleBadge } from '../../../components/Network/badges';
import { Page, PageControls, PageHeader, PageTitle } from '../../../components/Page';
import { AbsoluteTimestamp } from '../../../components/timestamps';
import { UserProfilePicture } from '../../../components/UserProfilePicture';
import { paths } from '../../../constants';
import { ResourceNotFoundError } from '../../../errors/errors';
import { useCloseDrawerCallback } from '../../../hooks/useCloseDrawerCallback';
import { checkDefinedOrThrow, expectDefinedOrThrow } from '../../../utils/expectDefinedOrThrow';
import { isDefined } from '../../../utils/isDefined';
import { makeDrawerLink, makeLink } from '../../../utils/makeLink';
import { formatFullName } from '../../../utils/users';

export const Meta: PagefileMetaFn = () => ({
  path: '/companies/:companyName/users',
});

const CellUserEmail = ({ value, row }: CellProps<api.UserResponse, string | null>) => (
  <HStack spacing={space(8)}>
    <UserProfilePicture user={row.original} /> {value}
  </HStack>
);

const columns: Column<api.UserResponse>[] = [
  {
    Header: 'Email',
    accessor: (row) => row.email,
    Cell: CellUserEmail,
  },
  {
    Header: 'Role',
    accessor: (row) => row.company_role,
    Cell: UserRoleBadge,
  },
  {
    Header: 'Name',
    accessor: (row) => formatFullName(row),
  },
  {
    Header: 'Created at',
    accessor: (row) => row.created_at,
    Cell: AbsoluteTimestamp,
  },
];

export default function CompanyUsers() {
  const { companyName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.CompanyUsers),
  );

  const users = useQuery(
    ['company', companyName, 'users'],
    () => fetchCompanyUsersJSON(companyName),
    { suspense: true },
  ).data;

  expectDefinedOrThrow(
    users,
    new ResourceNotFoundError(`Users for company ${companyName} not found`),
  );

  const drawerParams = Nav.useRegionParams('drawer', paths.drawers.UserDetail);

  const closeDrawer = useCloseDrawerCallback();

  return (
    <Page>
      <PageHeader>
        <PageTitle>
          Users on company{' '}
          <Link
            as={RouterLink}
            to={Nav.makeTo({
              root: makeLink(paths.pages.CompaniesList, {}),
              drawer: makeLink(paths.drawers.CompanySummary, { companyName }),
            })}
          >
            {companyName}
          </Link>
        </PageTitle>
        <PageControls>
          <Button
            as={RouterLink}
            variant="secondary"
            to={makeDrawerLink(paths.drawers.UsersAdd, { companyName })}
          >
            Add users
          </Button>
        </PageControls>
      </PageHeader>
      <AutoTable
        columns={columns}
        data={users}
        onRowDeselect={closeDrawer}
        isRowSelected={(row) => isDefined(drawerParams) && row.sid === drawerParams.userSid}
        linkProps={(row) => ({
          to: makeDrawerLink(paths.drawers.UserDetail, {
            companyName,
            userSid: row.sid,
          }),
        })}
      />
    </Page>
  );
}
