import { Alert } from '@meterup/metric';
import { DiffEditor, useMonaco } from '@monaco-editor/react';
import type { editor } from 'monaco-editor';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { PagefileMetaFn } from 'vite-plugin-pagefiles';

import { fetchControllerJSON } from '../../../api/controllersApi';
import { Nav } from '../../../components/Nav';
import { paths } from '../../../constants';
import { useConfigEditor } from '../../../context/ConfigEditorContext';
import { getErrorMessage, ResourceNotFoundError } from '../../../errors/errors';
import { useFeatureFlag } from '../../../hooks/useFeatureFlags';
import { styled } from '../../../stitches';
import schema from '../../../types/config/schema.json';
import { checkDefinedOrThrow, expectDefinedOrThrow } from '../../../utils/expectDefinedOrThrow';
import { isDefined, isDefinedAndNotEmpty } from '../../../utils/isDefined';

export const Meta: PagefileMetaFn = () => ({
  path: '/controllers/:controllerName/config',
});

const Container = styled('div', { display: 'flex', flexDirection: 'column', height: '100%' });

const EditorContainer = styled('div', {
  height: '100%',
  flex: 1,
  flexShrink: 0,
  overflow: 'hidden',
});

export default function ControllerConfig() {
  const monaco = useMonaco();

  useEffect(() => {
    monaco?.languages.json.jsonDefaults.setDiagnosticsOptions({
      schemas: [
        {
          fileMatch: ['**'],
          uri: '',
          schema,
        },
      ],
    });
  }, [monaco]);

  const [editor, setEditor] = useState<editor.IStandaloneCodeEditor | null>(null);

  const { controllerName } = checkDefinedOrThrow(
    Nav.useRegionParams('root', paths.pages.ControllerConfig),
  );

  const config = useConfigEditor();

  const networkInfo = useQuery(
    ['controller', controllerName],
    async () => fetchControllerJSON(controllerName),
    { suspense: true },
  ).data;

  expectDefinedOrThrow(
    networkInfo,
    new ResourceNotFoundError(`Controller ${controllerName} not found`),
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (editor) {
      return editor.onDidChangeModelContent(() => {
        config.setDraftText(editor.getValue());
      }).dispose;
    }
  }, [config, editor]);

  const canModifyConfig = useFeatureFlag('config-json-ui');

  return (
    <Container>
      {isDefinedAndNotEmpty(networkInfo.mboot_version) && (
        <Alert
          icon="attention"
          variant="negative"
          heading="Legacy controller"
          copy="This looks like a legacy controller. Writing to its config
        might not have the intended effect."
          cornerStyle="square"
        />
      )}
      {isDefined(config.saveDraftModelError) && (
        <Alert
          icon="attention"
          variant="negative"
          heading="Error while saving changes"
          copy={getErrorMessage(config.saveDraftModelError)}
          cornerStyle="square"
        />
      )}
      <EditorContainer>
        <DiffEditor
          originalLanguage="json"
          modifiedLanguage="json"
          original={config.originalText}
          modified={config.draftText}
          onMount={(ref) => setEditor(ref.getModifiedEditor())}
          options={{ minimap: { enabled: false }, readOnly: !canModifyConfig }}
        />
      </EditorContainer>
    </Container>
  );
}
