import { AdhocControllerEvent } from '@meterup/proto/esm/api';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { CellProps, Column } from 'react-table';

import { fetchControllerEvent } from '../../../api/controllersApi';
import { AutoTable } from '../../AutoTable/AutoTable';
import { AbsoluteTimestamp } from '../../timestamps';

interface EventLogsNetworkWidgetProps {
  controllerName: string;
}

const EventLogsNetworkWidget = ({ controllerName }: EventLogsNetworkWidgetProps) => {
  const logsData =
    useQuery(
      ['controller', controllerName, 'logs'],
      async () => fetchControllerEvent(controllerName),
      {
        suspense: true,
        refetchInterval: 1000,
      },
    ).data ?? [];

  const columns: Column<AdhocControllerEvent>[] = useMemo(
    () => [
      {
        Header: 'Event',
        accessor: (e) => e.event_type,
      },
      {
        Header: 'Message',
        accessor: (e) => e.message,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (e: CellProps<AdhocControllerEvent, string>) => (
          <div style={{ whiteSpace: 'normal' }}>{e.value}</div>
        ),
      },
      {
        Header: 'Time',
        accessor: (e) => e.created_at,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: (row: CellProps<any, string>) => (
          <div>
            <AbsoluteTimestamp value={row.value} />
          </div>
        ),
      },
    ],
    [],
  );

  return <AutoTable columns={columns} data={logsData} />;
};

export default EventLogsNetworkWidget;
