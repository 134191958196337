import {
  BasicSelect,
  BasicSelectItem,
  FieldContainer,
  ListItemHeader,
  ListTitle,
  PrimaryField,
  SecondaryField,
  Textarea,
  TextInput,
} from '@meterup/metric';
import { capitalize } from 'lodash';
import React from 'react';

import { FieldProvider } from '../../../../../components/FieldProvider';
import { LocalTimezoneAbbreviation } from '../../../../../components/timestamps';
import { categories, CATEGORY_NONE } from './form_data';

export const FormContent = () => {
  const tz = LocalTimezoneAbbreviation();

  return (
    <>
      <FieldContainer>
        <ListItemHeader>
          <ListTitle>Incident details</ListTitle>
        </ListItemHeader>
        <FieldProvider name="start_time">
          <SecondaryField
            label={`Start time (${tz})`}
            element={<TextInput type="datetime-local" />}
          />
        </FieldProvider>
        <FieldProvider name="end_time">
          <SecondaryField
            label={`End time (${tz})`}
            element={<TextInput type="datetime-local" />}
          />
        </FieldProvider>
      </FieldContainer>
      <FieldContainer>
        <FieldProvider name="notes">
          <PrimaryField label="Notes" element={<Textarea />} />
        </FieldProvider>
        <FieldProvider name="category">
          <SecondaryField
            label="Category"
            element={
              <BasicSelect>
                <BasicSelectItem value={CATEGORY_NONE}>None</BasicSelectItem>
                {categories.map((c) => (
                  <BasicSelectItem key={c} value={c}>
                    {capitalize(c)}
                  </BasicSelectItem>
                ))}
              </BasicSelect>
            }
          />
        </FieldProvider>
      </FieldContainer>
    </>
  );
};
