import {
  Alert,
  Button,
  Drawer,
  DrawerContent,
  DrawerControls,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
} from '@meterup/metric';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { PagefileMetaFn } from 'vite-plugin-pagefiles';

import { deleteUser, fetchCompanyUserJSON } from '../../../../api/usersApi';
import { CloseDrawerButton } from '../../../../components/CloseDrawerButton/CloseDrawerButton';
import { Nav } from '../../../../components/Nav';
import { paths } from '../../../../constants';
import { ResourceNotFoundError } from '../../../../errors/errors';
import { useCloseDrawerCallback } from '../../../../hooks/useCloseDrawerCallback';
import { checkDefinedOrThrow, expectDefinedOrThrow } from '../../../../utils/expectDefinedOrThrow';
import { makeDrawerLink } from '../../../../utils/makeLink';
import { formatFullName } from '../../../../utils/users';
import { UserNameAndEmailWidget } from './common/UserNameAndEmailWidget';
import { UserProfileAndPermissionsWidget } from './common/UserProfileAndPermissionsWidget';

export const Meta: PagefileMetaFn = () => ({
  path: '/companies/:companyName/users/:userSid/remove',
});

export default function UserRemove() {
  const { companyName, userSid } = checkDefinedOrThrow(
    Nav.useRegionParams('drawer', paths.drawers.UserDetail),
  );

  const user = useQuery(
    ['company', companyName, 'users', userSid],
    () => fetchCompanyUserJSON(companyName, userSid),
    { suspense: true },
  ).data;

  expectDefinedOrThrow(user, new ResourceNotFoundError(`User ${userSid} not found`));

  const closeDrawer = useCloseDrawerCallback();
  const client = useQueryClient();

  const deleteUserMutation = useMutation(() => deleteUser(companyName, userSid), {
    onSuccess: () => {
      closeDrawer();
      client.invalidateQueries(['company', companyName, 'users']);
    },
  });

  const formattedName = formatFullName(user);

  return (
    <Drawer>
      <DrawerHeader>
        <DrawerTitle>Remove user</DrawerTitle>
        <DrawerControls>
          <CloseDrawerButton />
        </DrawerControls>
      </DrawerHeader>

      <DrawerContent>
        {!formattedName && (
          <Alert
            icon="warning"
            variant="negative"
            heading="Please confirm"
            copy={
              <>
                Removing this user will revoke any access they have to Meter products associated
                with the <strong>{companyName}</strong> company. Do you wish to proceed?
              </>
            }
          />
        )}
        <UserNameAndEmailWidget user={user} />
        <UserProfileAndPermissionsWidget user={user} />
      </DrawerContent>
      <DrawerFooter>
        <DrawerControls>
          <Button
            variant="secondary"
            as={Link}
            to={makeDrawerLink(paths.drawers.UserDetail, { companyName, userSid })}
          >
            Cancel
          </Button>
          <Button variant="destructive" onClick={() => deleteUserMutation.mutate()}>
            Remove
          </Button>
        </DrawerControls>
      </DrawerFooter>
    </Drawer>
  );
}
