import { useQuery } from 'react-query';

import { fetchIncident } from '../../../../../api/controllersApi';

export const useControllerIncident = (controllerName: string, id: string) =>
  useQuery(
    ['controller', controllerName, 'incidents', id],
    () => fetchIncident(controllerName, id),
    {
      suspense: true,
    },
  ).data;
