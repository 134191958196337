import { ThemeProvider as MaterialThemeProvider } from '@material-ui/core/styles';
import { MobileSidebarToggle } from '@meterup/metric';
import { ErrorBoundary } from '@sentry/react';
import * as Sentry from '@sentry/react';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import { OverlayProvider } from 'react-aria';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import pagefileRoutes from 'virtual:pagefiles/pages';

import { getIdentity } from './api/api';
import { FatalErrorFallback } from './components/error_fallbacks';
import { Nav } from './components/Nav';
import { OverlayableContainer, overlayableRootCSS } from './components/overlays';
import { Redirects } from './components/Redirects';
import { NOCDesktopSidebar, NOCMobileSidebar } from './components/Sidebar/Sidebar';
import muiTheme from './components/theme';
import { useValidateFeatureFlags } from './hooks/useValidateFeatureFlags';
import { AppLayout } from './layouts/AppLayout';
import { SidebarAndMainLayout } from './layouts/SidebarAndMainLayout';
import { drawerRedirects, rootRedirects } from './redirects';
import { colors, darkThemeSelector, fonts, fontWeights, globalCss, styled } from './stitches';
import { isDefined } from './utils/isDefined';

const queryClient = new QueryClient();

const injectGlobalStyles = globalCss({
  'html, body, #root': {
    height: '100%',
    width: '100%',
    overscrollBehaviorY: 'none',
    position: 'fixed',
    overflow: 'hidden',
  },
  body: {
    background: colors.white,
    fontSize: 16,
    fontFamily: fonts.sans,
    fontWeight: fontWeights.regular,
    [darkThemeSelector]: {
      background: colors['gray-800'],
    },
    // Enable improved font rendering only on high-DPI displays
    '@media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 13/10), only screen and (min-resolution: 120dpi)':
      {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
      },
  },
  '#root': overlayableRootCSS,
});

const StyledOverlayProvider = styled(OverlayProvider, OverlayableContainer);

const NOCApp = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const openMobileMenu = () => setIsMobileMenuOpen(true);
  const closeMobileMenu = () => setIsMobileMenuOpen(false);

  const drawerLocation = Nav.useRegionLocation('drawer');
  const rootLocation = Nav.useRegionLocation('root');

  const PagefileRoutes = useRoutes(pagefileRoutes);

  return (
    <AppLayout>
      <SidebarAndMainLayout
        mobileSidebarToggle={
          <MobileSidebarToggle icon="menu" label="Navigation" onClick={openMobileMenu} />
        }
        desktopSidebar={<NOCDesktopSidebar />}
        mobileSidebar={isMobileMenuOpen ? <NOCMobileSidebar onClose={closeMobileMenu} /> : null}
        main={PagefileRoutes}
      />
      {drawerLocation && <Redirects redirects={drawerRedirects} location={drawerLocation} />}
      <Redirects redirects={rootRedirects} location={rootLocation} />
    </AppLayout>
  );
};

const IdentityCheck = () => {
  const returnPath = window.location.pathname;
  const identity = useQuery('identity', getIdentity, { suspense: false });

  const LDClient = useLDClient();

  useEffect(() => {
    if (isDefined(LDClient)) {
      if (isDefined(identity.data)) {
        LDClient.identify({
          key: identity.data.id.toString(),
          email: identity.data.username,
        });
      } else {
        LDClient.identify({
          anonymous: true,
        });
      }
    }
  }, [LDClient, identity]);

  useEffect(() => {
    const isCookiePresent = document.cookie.match(/^(.*;)?\s*noc.frontend.v2\s*=\s*[^;]+(.*)?$/);
    if (!isCookiePresent || identity.error) {
      window.location.href = `${import.meta.env.REACT_APP_PORTAL_REDIRECT_URL}&path=${returnPath}`;
    }
  }, [identity, returnPath]);

  return null;
};

const App = () => {
  useEffect(() => {
    injectGlobalStyles();
  });

  useValidateFeatureFlags();

  return (
    <Router basename="/">
      <StyledOverlayProvider>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary fallback={FatalErrorFallback}>
            <IdentityCheck />
            <Nav.Provider>
              <MaterialThemeProvider theme={muiTheme}>
                <NOCApp />
              </MaterialThemeProvider>
            </Nav.Provider>
          </ErrorBoundary>
        </QueryClientProvider>
      </StyledOverlayProvider>
    </Router>
  );
};

const withLaunchDarkly = withLDProvider({
  clientSideID: import.meta.env.LAUNCHDARKLY_CLIENT_ID,
  options: {
    sendEvents: false,
  },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
});

export default Sentry.withProfiler(withLaunchDarkly(App));
